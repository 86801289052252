.course_banner {
  min-height: 350px;
  position: relative;

  .course_image {
    width: 100%;
    height: 350px;

    img {
      width: 100%;
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  @media (max-width: $ipad_landscape) {
    min-height: 300px;
  }

  @media (max-width: $phone_landscape) {
    min-height: 350px;
  }

  @media (max-width: $iphone_ii) {
    background-position: -490px 0;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.05) 19.79%,
      rgba(0, 0, 0, 0.387083) 59.57%,
      rgba(0, 0, 0, 0.53) 100%
    );
  }

  .course_banner_text {
    max-width: 1160px;
    width: 100%;
    @extend .mx_auto;
    padding: 0 15px 46px;
    @extend .d_flex;
    @extend .align_items_end;
    @extend .justify_content_between;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;

    @media (max-width: $phone_landscape) {
      padding: 0 25px 30px;
      display: block;
      margin-left: 0 !important;
    }

    @media (max-width: 370px) {
      padding: 0 15px 30px;
    }

    h2 {
      @extend .national_semibold;
      font-size: 55px;
      line-height: 65px;
      @extend .text_capitalize;
      color: $white;
      margin: 0 0 25px;
      @include multiline_ellipsis(2);

      @media (max-width: $ipad_landscape) {
        font-size: 45px;
        margin: 0 0 15px;
      }

      @media (max-width: $phone_landscape) {
        font-size: 44px;
        line-height: 51px;
        margin: 0 0 30px;
      }

      @media (max-width: $iphone) {
        font-size: 34px;
        margin: 0 0 20px;
        line-height: 30px;
      }
    }

    .banner_button {
      @extend .d_flex;
      @extend .align_items_center;
    }

    .syllabus_btn {
      background: #333333;
      border-radius: 30px;
      height: 48px;
      @extend .border_0;
      @extend .national_semibold;
      font-size: 20px;
      line-height: 48px;
      @extend .text_center;
      @extend .text_uppercase;
      color: $white;
      padding: 0 32px;

      &:hover {
        background: $yellow;
        color: $black;
      }

      @media (max-width: $ipad_landscape) {
        padding: 0 22px;
      }

      @media (max-width: $phone_landscape) {
        padding: 0 18px;
      }

      @media (max-width: 370px) {
        font-size: 17px;
      }

      @media (max-width: $iphone) {
        font-size: 15px;
      }
    }

    .watch_video {
      padding-left: 38px;

      @media (max-width: $ipad_landscape) {
        padding-left: 20px;
      }

      a {
        padding-left: 60px;
        position: relative;
        @extend .national_regular;
        font-size: 24px;
        line-height: 29px;
        @extend .text_capitalize;
        color: $white;
        @extend .d_flex;
        @extend .align_items_center;
        min-height: 48px;
        pointer-events: none;

        @media (max-width: $ipad_landscape) {
          padding-left: 55px;
          font-size: 20px;
        }

        @media (max-width: $phone) {
          padding-left: 48px;
          font-size: 0;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 48px;
          height: 48px;
          background-color: $yellow;
          @extend .radius_rounded;
          pointer-events: all;
        }

        &::after {
          content: "";
          position: absolute;
          left: 20px;
          top: 18px;
          border: 7px solid transparent;
          border-right: 0;
          border-left: 11px solid $black;
          width: 10px;
          height: 14px;
        }

        &:hover {
          &::before {
            opacity: 0.8;
          }
        }
      }
    }

    .course_banner_right {
      @extend .d_flex;
      @extend .align_items_center;

      @media (max-width: $phone_landscape) {
        margin-top: 32px;
      }

      .update_btn {
        @extend .syllabus_btn;
        background: $yellow;
        color: $black;
        width: 275px;
        padding: 0 0 !important;

        &:hover {
          background: $black;
          color: $white;
        }

        @media (max-width: $ipad_landscape) {
          width: 240px;
          font-size: 18px;
        }

        @media (max-width: $phone_landscape) {
          padding: 0 19px;
        }
      }

      .que_btn {
        margin-left: 10px;
        width: 22px;
        height: 22px;
        @extend .border_0;
        @extend .p_0;
        background: url("../images/que.png");

        @media (max-width: $phone_landscape) {
          margin-left: 20px;
        }
      }
    }
  }
}

.courseDetail_tab_wrap {
  padding: 55px 15px 59px;
  max-width: 930px;

  @media (max-width: $phone) {
    padding: 20px 0px 80px;
  }

  @extend .mx_auto;

  .courseDetail_tab::-webkit-scrollbar {
    width: 0 !important;
  }

  .courseDetail_tab {
    overflow: auto;
  }

  .course_tab_list {
    text-align: center;
    margin: 0 0 60px;

    @media (max-width: $phone) {
      width: 485px;
      margin: 0;
    }

    .courseIntro_img {
      text-align: center;
    }

    li {
      display: inline-block;
      vertical-align: top;
      padding: 0 20px;

      span {
        position: relative;
        font-size: 20px;
        line-height: 24px;
        @extend .text_center;
        @extend .text_uppercase;
        color: $black;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: #000;
          width: 0;
          transition: all 0.5s ease-in-out;
          margin: 0 auto;
        }

        &:hover::before {
          width: 100%;
        }

        &.active {
          @extend .national_semibold;

          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  .course_tab_content {
    @media (max-width: $phone) {
      padding: 6px 0px 0;
    }

    .courseDetail {
      max-width: 870px;
      margin: 0 auto;

      &_title {
        @extend .national_semibold;
        font-size: 32px;
        line-height: 38px;
        @extend .text_capitalize;
        color: $black;
        margin: 0 0 20px;

        @media (max-width: $phone) {
          font-size: 22px;
          margin: 0 0 7px;
          padding: 0 30px;
        }
      }

      p {
        .DraftEditor-root {
          font-size: 18px;
          line-height: 22px;
          color: #565656;
          padding: 0 0 !important;
        }

        @media (max-width: $phone) {
          padding: 0 30px;
        }
      }
    }

    &.next_up {
      .tab_next_content {
        // font-family: 'Roboto';
        // font-style: normal;
        .DraftEditor-root {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          margin-bottom: 45px;
        }
      }

      .module_list {
        .module {
          background: #f5f6f5;
          border-radius: 5px;
          padding: 26px 36px;
          margin-bottom: 10px;

          p {
            // font-family: 'Roboto';
            // font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              width: 15px;
              height: 15px;
              background: url(../images/nextup_plus.png) no-repeat;
              background-size: cover;
              background-position: center;
              display: block;
              cursor: pointer;
            }
          }

          ul {
            display: none;

            li {
              // font-family: 'Roboto';
              // font-style: normal;
              .DraftEditor-root {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                margin-bottom: 15px;
              }
            }
          }

          &.active {
            p {
              margin-bottom: 28px;
              color: #990000;

              span {
                cursor: pointer;
                background: url(../images/nextup_minus.png) no-repeat;
                height: 5px;
                background-size: cover;
                background-position: center;
              }
            }

            ul {
              display: block;
            }
          }
        }
      }
    }

    &.tools_tab {
      .course_syllabus_wrap {
        padding: 60px 0;

        .tools_content {
          width: 100%;
          max-width: 865px;
          margin: auto;
          padding-left: 85px;
          position: relative;
          margin-bottom: 70px;

          &::after {
            content: "";
            display: block;
            width: 39px;
            height: 39px;
            background: url(../images/resources_icon.png) no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: -10px;
            left: 20px;
          }

          h2 {
            // font-family: 'Roboto';
            // font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 30px;
          }

          p {
            // font-family: 'Roboto';
            // font-style: normal;
            .DraftEditor-root {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #000000;
              margin-bottom: 28px;
            }
          }

          a {
            color: #1976d2;
          }
        }
      }
    }
  }

  ul.course_sub_list {
    margin: 40px 0 40px;

    @media (max-width: $phone) {
      margin: 30px 0 50px;
      padding: 7px 0 7px 10px;
      background: radial-gradient(
        82.4% 120556.35% at 0% 50.01%,
        #e2e2e2 0%,
        rgba(226, 226, 226, 0) 100%
      );
    }

    li {
      margin-right: 25px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;

      @media (max-width: $phone) {
        margin: 0 30px;

        &:last-child {
          margin-right: 0;
        }
      }

      @media (max-width: 350px) {
        margin: 0 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      span {
        font-size: 32px;
        line-height: 38px;
        @extend .text_capitalize;
        color: $gray;

        @media (max-width: $phone) {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }

    .active span {
      @extend .national_semibold;
      color: $black;
    }
  }

  .course_intro_wrap {
    position: relative;

    .slide_arrow {
      position: absolute;
      left: 0;
      right: 0;
      top: 48px;

      @media (max-width: $phone) {
        max-width: 280px;
        margin: 0 auto;
      }

      .arrow {
        line-height: 1;
        height: 24px;
        position: absolute;
        left: 0;
        top: 0;

        @media (max-width: $phone) {
          height: 14px;

          svg {
            width: 8px;
            height: 14px;
          }
        }

        cursor: pointer;

        &.next {
          left: auto;
          right: 0;
          transform: rotate(180deg);
        }

        &.disable {
          pointer-events: none;

          svg path {
            fill: #00000020;
          }
        }
      }
    }
  }

  .courseIntro {
    @media (max-width: $phone) {
      padding: 0 30px;
    }

    &_img {
      text-align: center;
    }

    &_title {
      @extend .national_semibold;
      font-size: 20px;
      line-height: 24px;
      @extend .text_center;
      color: $black;
      margin: 0 0 10px;

      @media (max-width: $phone) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    &_subtitle {
      @extend .national_regular;
      font-size: 18px;
      line-height: 22px;
      @extend .text_center;
      text-transform: none;
      color: #565656;
      max-width: 215px;
      @extend .mx_auto;
      margin-bottom: 1rem;

      @media (max-width: $phone) {
        font-size: 14px;
        line-height: 17px;
        max-width: 165px;
      }
    }

    &_email {
      @extend .national_regular;
      font-size: 18px;
      line-height: 22px;
      @extend .text_center;
      color: #990000;
      max-width: 215px;
      @extend .mx_auto;
      @extend .text_lowercase;

      @media (max-width: $phone) {
        font-size: 14px;
        line-height: 17px;
        max-width: 165px;
      }
    }

    &_prof {
      font-size: 20px;
      line-height: 24px;
      @extend .text_center;
      color: $black;
      margin: 10px 0 35px;

      @media (max-width: $phone) {
        font-size: 16px;
        line-height: 19px;
        margin: 10px 0 20px;
      }
    }

    &_content {
      .DraftEditor-root {
        font-size: 18px;
        line-height: 22px;
        color: #565656;
        padding: 0 !important;
      }

      @media (max-width: $phone) {
        color: #686868;
      }
    }
  }

  .user_profile {
    img {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      border: 2px solid $white;
      box-shadow: 0 0 15px rgb(0 0 0 / 15%);
      background: aliceblue;
      margin: 0 auto 20px;
      overflow: hidden;
      object-fit: cover;

      img {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .course_syllabus_wrap {
    background: #f2f2f2;
    border-radius: 5px;
    padding: 30px 0;
    // min-height: 620px;

    .course_syll_container{
      line-height: 1.5;
    }
    
    @media (max-width: $ipad_landscape) {
      padding: 30px 20px;
    }

    @media (max-width: $phone) {
      padding: 30px 18px 30px 18px;
      margin: 35px 30px 0;
    }

    @media (max-width: $iphone_ii) {
      margin: 18px 30px 0;
      padding: 30px 0px 30px 0px;
    }

    .course_des{
      font-size: 1.7rem;
    }
    
    ul.syllabus_sub_tab {
      @extend .course_syll_container;
      @extend .text_center;

      @media (max-width: $iphone_ii) {
        padding-left: 18px;
        padding-right: 0;
        // text-align: left;
        padding: 0 15px 0 15px !important;
      }

      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        width: 100%;
        height: 1px;
        border-bottom: 0.75px solid #9e9e9e;
      }

      li {
        @extend .d_inline_block;
        vertical-align: top;
        font-size: 15px;
        @extend .text_center;
        color: #9e9e9e;
        background: $white;
        padding: 0 25px;
        height: 27px;
        line-height: 27px;
        position: relative;
        @extend .cursor_pointer;

        @media (max-width: $phone) {
          padding: 0 6px;
          font-size: 13px;
        }

        @media (max-width: $iphone_ii) {
          padding: 0 11px;
          font-size: 14px;

          &:last-child {
            padding: 0 7px;
          }
        }

        @media (max-width: 365px) {
          padding: 0 6px;
          font-size: 12px;
        }
      }

      .active {
        background-color: $maroon;
        color: $white;
      }
    }

    .syllabus_ouline_wrap {
      @extend .course_syll_container;
      margin-top: 28px !important;

      .syll_outline {
        padding-left: 25px;

        @media (max-width: $iphone_ii) {
          padding-left: 0px;
        }

        .main_status {
          font-size: 10px;
          @extend .text_center;
          color: $white;
          background: #4caf50;
          border-radius: 20px;
          height: 18px;
          line-height: 14px;
          padding: 0 8px;
          @extend .national_regular;
          letter-spacing: 0.3px;
          margin: -1px 0 0 auto;
          @extend .d_flex_center;

          @media (max-width: $phone) {
            padding: 0 6px !important;
            letter-spacing: normal;
            width: 60px !important;
            margin-left: auto;
            flex: 0 0 60px;
          }
        }

        .syll_outline_title {
          padding-left: 20px;
          position: relative;
          @extend .d_flex;
          @extend .justify_content_between;
          @extend .align_items_center;
          font-size: 15px;
          line-height: 18px;
          color: $black;
          @extend .text_capitalize;

          span.icon {
            position: absolute;
            left: 0px;
            top: 1px;
            width: 13px;
            height: 13px;
            background: url("../images/cour_minus.png") no-repeat;
            cursor: pointer;

            &.plus {
              background: url("../images/cour_plus.png") no-repeat;
            }
          }
        }

        .status {
          @extend.d_flex_center;
          white-space: nowrap;
          font-size: 10px;
          @extend .text_center;
          color: $white;
          border-radius: 20px;
          height: 18px;
          line-height: 15px;
          padding: 0 7px;
          @extend .national_regular;
          letter-spacing: 0.3px;
          background-color: #494949;
          cursor: pointer;
          margin-left: auto;

          @media (max-width: $phone) {
            padding: 0 6px !important;
            letter-spacing: normal;
            width: 60px !important;
            margin-left: auto;
            flex: 0 0 60px;
          }
        }

        .syll_outline_content {
          padding: 13px 0 0 25px;

          @media (max-width: $iphone_ii) {
            padding: 20px 0 0 18px;
          }

          .syll_outline_exp {
            font-size: 15px;
            line-height: 18px;
            color: #000000;
            @extend .d_flex;
            @extend .align_items_center;
            @extend .justify_content_start;
            margin: 0 0 12px;
            cursor: pointer;

            &:hover {
              color: #990000;
            }

            .syll_count {
              margin-right: 5px;
              align-self: flex-start;
            }

            span:nth-child(2) {
              margin-right: 7px;
            }
          }
        }
      }
    }

    .usc_ad_main {
      padding-top: 65px;

      @media (max-width: $iphone_ii) {
        padding-top: 40px;
        padding-left: 18px;
        padding-right: 18px;
      }

      .usc_discussion_main {
        background: #eaeaea;
        border-radius: 5px;
        max-width: 720px;
        width: 100%;
        margin: 0 auto 27px;

        @media (max-width: $phone) {
          margin: 0 auto 18px;
        }

        .usc_profile_msg {
          padding: 12px 30px 20px 18px;

          @media (max-width: $phone) {
            padding: 17px 18px 5px 18px;
          }

          .usc_ad_profile {
            display: flex;
            align-items: center;
            margin-bottom: 14px;

            .user_profile {
              img {
                width: 40px;
                height: 40px;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                margin: 0;
              }
            }

            .usc_user {
              width: calc(100% - 40px);
              padding-left: 10px;

              h4 {
                text-align: left;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: #000000;
                text-transform: capitalize;
                letter-spacing: 0.2px;
              }

              span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
              }
            }

            .pin {
              background: url("../images/pin.svg");
              width: 15px;
              height: 15px;
              font-size: 0;
              cursor: pointer;

              @media (max-width: $phone) {
                align-self: flex-start;
              }
            }

            .unpin {
              cursor: pointer;
              background: url("../images/active_pin.svg");
            }
          }

          .usc_msg {
            .messages_box_text_more {
              pre {
                margin: 0;

                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }

                a {
                  color: #2f3ab2;
                 
                }

                p {
                  font-size: 16px;
                  line-height: 17px;
                  color: #565656;

                  // @include multiline_ellipsis(4);
                  .lightbox-wrapper {
                    .lightbox {
                      .meta {
                        display: none;
                      }
                    }
                  }
                }

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 1rem;
                        border-radius: 0;
                        margin: 0 0 0.5rem;
                      }
                    }
                  }
                }
              }
            }

            .show_more {
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
              text-align: right;
              text-transform: capitalize;
              color: #000000;
              display: block;
              text-align: right;
              margin-top: -12px;
              cursor: pointer;

              @media (max-width: $ipad_landscape) {
                margin-top: 0;
              }
            }
          }
        }

        .usc_ad_img {
          img {
            width: 100%;
            max-width: 100%;
            @extend .d_block;
          }

          .usc_bottom_con {
            background: #c1c1c1;
            padding: 13px 15px 9px 20px;

            @media (max-width: $phone) {
              padding: 14px 24px 10px 18px;
            }

            .article_title {
              @extend .national_semibold;
              font-size: 12px;
              line-height: 15px;
              color: $black;
              @extend .text_left;

              @media (max-width: $phone) {
                font-size: 14px;
                line-height: 16px;
                max-width: 249px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }

            a {
              font-size: 12px;
              line-height: 15px;
              color: $black;
              @extend .national_regular;
            }
          }
        }

        .usc_comment_wrap {
          padding: 20px 15px 20px 20px;
          line-height: 1;

          @media (max-width: $phone) {
            padding: 19px 15px 19px 18px;
          }

          div {
            @extend .d_inline_block;
            vertical-align: top;
          }

          .like_wrap,
          .comment_wrap {
            padding-left: 18px;
            position: relative;
            font-size: 12px;
            line-height: 15px;
            @extend .text_capitalize;
            color: $black;
            margin-right: 13px;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: -1px;
              width: 14px;
              height: 14px;
              background: url("../images/like.svg") no-repeat;
            }
          }

          .comment_wrap {
            &::before {
              top: 1px;
              background: url("../images/comment.svg") no-repeat;
            }
          }

          .like_wrap_active {
            @extend .like_wrap;

            &::before {
              background: url("../images/active_like.svg") no-repeat;
            }
          }

          .comment_wrap_active {
            @extend .like_wrap;

            &::before {
              top: 1px;
              background: url("../images/active_comment.svg") no-repeat;
            }
          }

          .view_comment {
            font-size: 12px;
            line-height: 15px;
            @extend .text_capitalize;
            color: $black;
            margin-left: 32px;
            @extend .cursor_pointer;

            @media (max-width: $phone) {
              margin-left: 0px;
            }

            &:hover {
              color: #990000;
            }

            &.reply_comment {
              float: right;

              @media (max-width: $phone) {
                margin-left: 0;
              }
            }
          }
        }
      }

      .usc_discussion_main_for_admin {
        background: #ffffff;
        border-radius: 5px;
        max-width: 720px;
        width: 100%;
        margin: 0 auto 19px;

        @media (max-width: $phone) {
          margin: 0 auto 18px;
        }

        .usc_profile_msg {
          padding: 12px 30px 20px 18px;

          @media (max-width: $phone) {
            padding: 17px 18px 5px 18px;
          }

          .usc_ad_profile {
            display: flex;
            align-items: center;
            margin-bottom: 14px;

            .user_profile {
              img {
                width: 40px;
                height: 40px;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                margin: 0;
              }
            }

            .usc_user {
              width: calc(100% - 40px);
              padding-left: 10px;

              h4 {
                text-align: left;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: #000000;
                text-transform: capitalize;
                letter-spacing: 0.2px;
              }

              span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
              }
            }

            .pin {
              background: url("../images/pin.svg");
              width: 15px;
              height: 15px;
              font-size: 0;
              cursor: pointer;

              @media (max-width: $phone) {
                align-self: flex-start;
              }
            }

            .unpin {
              cursor: pointer;
              background: url("../images/active_pin.svg");
            }
          }

          .usc_msg {
            .messages_box_text_more {
              pre {
                margin: 0;

                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }

                a {
                  color: #2f3ab2;
                  display: block;
                }

                p {
                  font-size: 16px;
                  line-height: 17px;
                  color: #565656;

                  // @include multiline_ellipsis(4);
                  .lightbox-wrapper {
                    .lightbox {
                      .meta {
                        display: none;
                      }
                    }
                  }
                }

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 1rem;
                        border-radius: 0;
                        margin: 0 0 0.5rem;
                      }
                    }
                  }
                }
              }
            }

            .show_more {
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
              text-align: right;
              text-transform: capitalize;
              color: #000000;
              display: block;
              text-align: right;
              margin-top: -12px;
              cursor: pointer;

              @media (max-width: $ipad_landscape) {
                margin-top: 0;
              }
            }
          }
        }

        .usc_ad_img {
          img {
            width: 100%;
            max-width: 100%;
            @extend .d_block;
          }

          .usc_bottom_con {
            background: #c1c1c1;
            padding: 13px 15px 9px 20px;

            @media (max-width: $phone) {
              padding: 14px 24px 10px 18px;
            }

            .article_title {
              @extend .national_semibold;
              font-size: 12px;
              line-height: 15px;
              color: $black;
              @extend .text_left;

              @media (max-width: $phone) {
                font-size: 14px;
                line-height: 16px;
                max-width: 249px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }

            a {
              font-size: 12px;
              line-height: 15px;
              color: $black;
              @extend .national_regular;
            }
          }
        }

        .usc_comment_wrap {
          padding: 20px 15px 20px 20px;
          line-height: 1;

          @media (max-width: $phone) {
            padding: 19px 15px 19px 18px;
          }

          div {
            @extend .d_inline_block;
            vertical-align: top;
          }

          .like_wrap,
          .comment_wrap {
            padding-left: 18px;
            position: relative;
            font-size: 12px;
            line-height: 15px;
            @extend .text_capitalize;
            color: $black;
            margin-right: 13px;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: -1px;
              width: 14px;
              height: 14px;
              background: url("../images/like.svg") no-repeat;
            }
          }

          .comment_wrap {
            &::before {
              top: 1px;
              background: url("../images/comment.svg") no-repeat;
            }
          }

          .like_wrap_active {
            @extend .like_wrap;

            &::before {
              background: url("../images/active_like.svg") no-repeat;
            }
          }

          .comment_wrap_active {
            @extend .like_wrap;

            &::before {
              top: 1px;
              background: url("../images/active_comment.svg") no-repeat;
            }
          }

          .view_comment {
            font-size: 12px;
            line-height: 15px;
            @extend .text_capitalize;
            color: $black;
            margin-left: 32px;
            @extend .cursor_pointer;

            @media (max-width: $phone) {
              margin-left: 0px;
            }

            &:hover {
              color: #990000;
            }

            &.reply_comment {
              float: right;

              @media (max-width: $phone) {
                margin-left: 0;
              }
            }
          }
        }
      }

      .usc_replaycomment_wrap {
        max-width: 720px;
        width: 100%;
        margin: 0 auto 27px;
.usc_comment_replay_admin_con{
  margin: 6px 0 6px 38px;
  background: #eaeaea;
  border-radius: 5px;
  padding: 14px 32px 24px 17px;

  @media (max-width: $phone) {
    margin: 6px 0 6px 18px;
    padding: 17px 18px 20px 18px;
  }

  .usc_ad_profile {
    @extend .d_flex;
    @extend .align_items_center;
    margin-bottom: 14px;

    .user_profile {
      img {
        width: 40px;
        height: 40px;
        box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
        margin: 0;
      }
    }

    .usc_user {
      width: calc(100% - 40px);
      padding-left: 10px;

      h4 {
        @extend .text_left;
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        color: $black;
        @extend .text_capitalize;
        letter-spacing: 0.2px;
      }

      span {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .usc_msg {
    .messages_box_text_more {
      pre {
        margin: 0;

        .lightbox-wrapper {
          .lightbox {
            .meta {
              display: none;
            }
          }
        }

        a {
          color: #2f3ab2;
          display:block;
        }

        p {
          font-size: 16px;
          line-height: 17px;
          color: #565656;

          // @include multiline_ellipsis(4);
          .lightbox-wrapper {
            .lightbox {
              .meta {
                display: none;
              }
            }
          }
        }

        .ant-skeleton {
          .ant-skeleton-content {
            .ant-skeleton-paragraph {
              li {
                height: 1rem;
                border-radius: 0;
                margin: 0 0 0.5rem;
              }
            }
          }
        }
      }
    }

    .edit_delete_wrap {
      margin: -5px 0 0;
      // text-align: right;

      @media (max-width: $phone) {
        margin: 15px 0 0;
      }

      .usc_comment_wrap {
        // display: flex;
        // justify-content: space-between;
        // //padding: 20px 15px 20px 20px;
        padding-top: 40px;
        // line-height: 1;

        // @media (max-width: $phone) {
        //   padding: 19px 15px 19px 18px;
        // }

        .reply_edit_delete_icon {
          // @extend .d_inline_block;
          // vertical-align: top;
          float: right;

          .edit_detele_icon {
            float: left;
          }
        }

        .like_wrap,
        .comment_wrap {
          padding-left: 18px;
          position: relative;
          font-size: 12px;
          line-height: 15px;
          @extend .text_capitalize;
          color: $black;
          margin-right: 13px;
          float: left;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: -1px;
            width: 14px;
            height: 14px;
            background: url("../images/like.svg") no-repeat;
          }
        }

        .comment_wrap {
          &::before {
            top: 1px;
            background: url("../images/comment.svg") no-repeat;
          }
        }

        .like_wrap_active {
          @extend .like_wrap;

          &::before {
            background: url("../images/active_like.svg") no-repeat;
          }
        }

        .comment_wrap_active {
          @extend .like_wrap;

          &::before {
            top: 1px;
            background: url("../images/active_comment.svg") no-repeat;
          }
        }

        .view_comment {
          font-size: 12px;
          line-height: 15px;
          @extend .text_capitalize;
          color: $black;
          margin-left: 32px;
          @extend .cursor_pointer;
          float: left;

          @media (max-width: $phone) {
            margin-left: 0;
          }

          &:hover {
            color: #990000;
          }

          // &.reply_comment {
          //   float: right;
          // }
        }
      }

      span {
        cursor: pointer;
        line-height: 1;
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;

        img {
          height: 16px;
          width: 16px;
        }
      }

      .delete_commnet {
        img {
          height: 14px;
          width: 14px;
        }
      }
    }

    .comment_form + .edit_delete_wrap {
      margin: 5px 0 0;
    }
  }

  .comment_form {
    margin: 0;
  }
}

        .usc_comment_replay {
          margin: 6px 0 6px 38px;
          background: #eaeaea;
          border-radius: 5px;
          padding: 14px 32px 24px 17px;

          @media (max-width: $phone) {
            margin: 6px 0 6px 18px;
            padding: 17px 18px 20px 18px;
          }

          .usc_ad_profile {
            @extend .d_flex;
            @extend .align_items_center;
            margin-bottom: 14px;

            .user_profile {
              img {
                width: 40px;
                height: 40px;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                margin: 0;
              }
            }

            .usc_user {
              width: calc(100% - 40px);
              padding-left: 10px;

              h4 {
                @extend .text_left;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: $black;
                @extend .text_capitalize;
                letter-spacing: 0.2px;
              }

              span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
              }
            }
          }

          .usc_msg {
            .messages_box_text_more {
              pre {
                margin: 0;

                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }

                a {
                  color: #2f3ab2;
                  display:block;
                }

                p {
                  font-size: 16px;
                  line-height: 17px;
                  color: #565656;

                  // @include multiline_ellipsis(4);
                  .lightbox-wrapper {
                    .lightbox {
                      .meta {
                        display: none;
                      }
                    }
                  }
                }

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 1rem;
                        border-radius: 0;
                        margin: 0 0 0.5rem;
                      }
                    }
                  }
                }
              }
            }

            .edit_delete_wrap {
              margin: -5px 0 0;
              // text-align: right;

              @media (max-width: $phone) {
                margin: 15px 0 0;
              }

              .usc_comment_wrap {
                // display: flex;
                // justify-content: space-between;
                // //padding: 20px 15px 20px 20px;
                padding-top: 40px;
                // line-height: 1;

                // @media (max-width: $phone) {
                //   padding: 19px 15px 19px 18px;
                // }

                .reply_edit_delete_icon {
                  // @extend .d_inline_block;
                  // vertical-align: top;
                  float: right;

                  .edit_detele_icon {
                    float: left;
                  }
                }

                .like_wrap,
                .comment_wrap {
                  padding-left: 18px;
                  position: relative;
                  font-size: 12px;
                  line-height: 15px;
                  @extend .text_capitalize;
                  color: $black;
                  margin-right: 13px;
                  float: left;

                  &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -1px;
                    width: 14px;
                    height: 14px;
                    background: url("../images/like.svg") no-repeat;
                  }
                }

                .comment_wrap {
                  &::before {
                    top: 1px;
                    background: url("../images/comment.svg") no-repeat;
                  }
                }

                .like_wrap_active {
                  @extend .like_wrap;

                  &::before {
                    background: url("../images/active_like.svg") no-repeat;
                  }
                }

                .comment_wrap_active {
                  @extend .like_wrap;

                  &::before {
                    top: 1px;
                    background: url("../images/active_comment.svg") no-repeat;
                  }
                }

                .view_comment {
                  font-size: 12px;
                  line-height: 15px;
                  @extend .text_capitalize;
                  color: $black;
                  margin-left: 32px;
                  @extend .cursor_pointer;
                  float: left;

                  @media (max-width: $phone) {
                    margin-left: 0;
                  }

                  &:hover {
                    color: #990000;
                  }

                  // &.reply_comment {
                  //   float: right;
                  // }
                }
              }

              span {
                cursor: pointer;
                line-height: 1;
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                img {
                  height: 16px;
                  width: 16px;
                }
              }

              .delete_commnet {
                img {
                  height: 14px;
                  width: 14px;
                }
              }
            }

            .comment_form + .edit_delete_wrap {
              margin: 5px 0 0;
            }
          }

          .comment_form {
            margin: 0;
          }
        }

        .usc_comment_replay_isUSer {
          margin: 6px 0 6px 38px;
          background: #eaeaea;
          border-radius: 5px;
          padding: 14px 32px 24px 17px;

          @media (max-width: $phone) {
            margin: 6px 0 6px 18px;
            padding: 17px 18px 20px 18px;
          }

          .usc_ad_profile {
            @extend .d_flex;
            @extend .align_items_center;
            margin-bottom: 14px;

            .user_profile {
              img {
                width: 40px;
                height: 40px;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                margin: 0;
              }
            }

            .usc_user {
              width: calc(100% - 40px);
              padding-left: 10px;

              h4 {
                @extend .text_left;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: $black;
                @extend .text_capitalize;
                letter-spacing: 0.2px;
              }

              span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
              }
            }
          }

          .usc_msg {
            .messages_box_text_more {
              pre {
                margin: 0;

                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }

                a {
                  color: #2f3ab2;
                }

                p {
                  font-size: 16px;
                  line-height: 17px;
                  color: #565656;

                  // @include multiline_ellipsis(4);
                  .lightbox-wrapper {
                    .lightbox {
                      .meta {
                        display: none;
                      }
                    }
                  }
                }

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 1rem;
                        border-radius: 0;
                        margin: 0 0 0.5rem;
                      }
                    }
                  }
                }
              }
            }

            .edit_delete_wrap {
              margin: -5px 0 0;
              // text-align: right;

              @media (max-width: $phone) {
                margin: 15px 0 0;
              }

              .usc_comment_wrap {
                // display: flex;
                // justify-content: space-between;
                // //padding: 20px 15px 20px 20px;
                padding-top: 40px;
                // line-height: 1;

                // @media (max-width: $phone) {
                //   padding: 19px 15px 19px 18px;
                // }

                .reply_edit_delete_icon {
                  // @extend .d_inline_block;
                  // vertical-align: top;
                  float: right;

                  .edit_detele_icon {
                    float: left;
                  }
                }

                .like_wrap,
                .comment_wrap {
                  padding-left: 18px;
                  position: relative;
                  font-size: 12px;
                  line-height: 15px;
                  @extend .text_capitalize;
                  color: $black;
                  margin-right: 13px;
                  float: left;

                  &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -1px;
                    width: 14px;
                    height: 14px;
                    background: url("../images/like.svg") no-repeat;
                  }
                }

                .comment_wrap {
                  &::before {
                    top: 1px;
                    background: url("../images/comment.svg") no-repeat;
                  }
                }

                .like_wrap_active {
                  @extend .like_wrap;

                  &::before {
                    background: url("../images/active_like.svg") no-repeat;
                  }
                }

                .comment_wrap_active {
                  @extend .like_wrap;

                  &::before {
                    top: 1px;
                    background: url("../images/active_comment.svg") no-repeat;
                  }
                }

                .view_comment {
                  font-size: 12px;
                  line-height: 15px;
                  @extend .text_capitalize;
                  color: $black;
                  margin-left: 32px;
                  @extend .cursor_pointer;
                  float: left;

                  @media (max-width: $phone) {
                    margin-left: 0;
                  }

                  &:hover {
                    color: #990000;
                  }

                  // &.reply_comment {
                  //   float: right;
                  // }
                }
              }

              span {
                cursor: pointer;
                line-height: 1;
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                img {
                  height: 16px;
                  width: 16px;
                }
              }

              .delete_commnet {
                img {
                  height: 14px;
                  width: 14px;
                }
              }
            }

            .comment_form + .edit_delete_wrap {
              margin: 5px 0 0;
            }
          }

          .comment_form {
            margin: 0;
          }
        }

        .comment_form {
          margin-left: 38px;
          position: relative;
          border-radius: 5px;

          @media (max-width: $phone) {
            margin-left: 18px;
          }

          .editor {
            border: 1px solid #ddd;
            background: #fff;
            padding: 5px;

            .rdw-editor-toolbar {
              background: #f8f8f8;
            }

            .rdw-image-modal {
              .rdw-image-modal-size {
                display: none;
              }
            }

            .rdw-editor-toolbar {
              border: none;
              border-bottom: 1px solid #ffffff;

              .rdw-link-wrapper {
                .rdw-link-modal {
                  height: 235px;
                }
              }
            }

            .customEditor_textarea {
              .DraftEditor-root {
                .DraftEditor-editorContainer {
                  padding: 0 10px;
                  min-height: 50px;

                  .public-DraftEditor-content {
                    div {
                      figure {
                        .rdw-image-alignment {
                          .rdw-image-imagewrapper {
                            img {
                              object-fit: cover;
                              width: 100%;
                              height: auto;
                              aspect-ratio: 16/9;
                            }
                          }
                        }
                      }
                    }

                    .public-DraftStyleDefault-block {
                      margin: unset;
                    }
                     .rdw-link-decorator-wrapper{
                        display:block
                    }
                  }
                }
              }
            }

            @media (max-width: $phone) {
              .rdw-editor-toolbar.customEditor_toolbar {
                padding-right: 30px;
              }

              .rdw-option-wrapper {
                margin: 0 1px;
                min-width: 20px;
                padding: 0 3px;

                img {
                  width: 11px;
                }
              }

              .rdw-image-modal,
              .rdw-emoji-modal,
              .rdw-link-modal {
                left: auto;
                right: 0;
              }
            }
          }

          .submit_wrap {
            position: absolute;
            right: 15px;
            top: 14px;

            &_icon_active {
              cursor: pointer;
              width: 12px;
              height: 12px;
              border: 0 !important;
              background-color: #494949;
              -webkit-mask-image: url("../images/send.svg");
              mask-image: url("../images/send.svg");

              &:hover {
                @include filter(brightness(0));
              }
            }

            &_icon_disabled {
              @extend .submit_wrap_icon_active;
              background-color: #c1c1c1;
              cursor: not-allowed;
            }

            &_icon_save_active {
              @extend .submit_wrap_icon_active;
              width: 16px;
              height: 16px;
              background-color: #494949;
              -webkit-mask-image: url("../images/save.svg");
              mask-image: url("../images/save.svg");
            }

            &_icon_save_disabled {
              @extend .submit_wrap_icon_active;
              background-color: #c1c1c1;
              width: 16px;
              height: 16px;
              cursor: not-allowed;
              -webkit-mask-image: url("../images/save.svg");
              mask-image: url("../images/save.svg");
            }
          }
        }
      }

      .usc_reply_sub_comment_wrap {
        max-width: 680px;
        width: 100%;
        margin-left: auto;
        .usc_comment_replays{
          margin: 6px 0 6px 38px;
          background: #FAF0E0;
          border-radius: 5px;
          padding: 14px 32px 24px 17px;

          @media (max-width: $phone) {
            margin: 6px 0 6px 18px;
            padding: 17px 18px 20px 18px;
          }

          .usc_ad_profile {
            @extend .d_flex;
            @extend .align_items_center;
            margin-bottom: 14px;

            .user_profile {
              img {
                width: 40px;
                height: 40px;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                margin: 0;
              }
            }

            .usc_user {
              width: calc(100% - 40px);
              padding-left: 10px;

              h4 {
                @extend .text_left;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: $black;
                @extend .text_capitalize;
                letter-spacing: 0.2px;
              }

              span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
              }
            }
          }

          .usc_msg {
            .messages_box_text_more {
              pre {
                margin: 0;

                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }

                a {
                  color: #2f3ab2;
                 
                }

                p {
                  font-size: 16px;
                  line-height: 17px;
                  color: #565656;

                  // @include multiline_ellipsis(4);
                  .lightbox-wrapper {
                    .lightbox {
                      .meta {
                        display: none;
                      }
                    }
                  }
                }

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 1rem;
                        border-radius: 0;
                        margin: 0 0 0.5rem;
                      }
                    }
                  }
                }
              }
            }

            .edit_delete_wrap {
              margin: -5px 0 0;
              text-align: right;

              @media (max-width: $phone) {
                margin: 15px 0 0;
              }

              span {
                cursor: pointer;
                line-height: 1;
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                img {
                  height: 16px;
                  width: 16px;
                }
              }

              .delete_commnet {
                img {
                  height: 14px;
                  width: 14px;
                }
              }
            }

            .comment_form + .edit_delete_wrap {
              margin: 5px 0 0;
            }
          }

          .comment_form {
            margin: 0;
          }
        }
        .usc_comment_replay_Mentor{
          margin: 6px 0 6px 38px;
          background: #fef5d2;
          border-radius: 5px;
          padding: 14px 32px 24px 17px;

          @media (max-width: $phone) {
            margin: 6px 0 6px 18px;
            padding: 17px 18px 20px 18px;
          }

          .usc_ad_profile {
            @extend .d_flex;
            @extend .align_items_center;
            margin-bottom: 14px;

            .user_profile {
              img {
                width: 40px;
                height: 40px;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                margin: 0;
              }
            }

            .usc_user {
              width: calc(100% - 40px);
              padding-left: 10px;

              h4 {
                @extend .text_left;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: $black;
                @extend .text_capitalize;
                letter-spacing: 0.2px;
              }

              span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
              }
            }
          }

          .usc_msg {
            .messages_box_text_more {
              pre {
                margin: 0;

                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }

                a {
                  color: #2f3ab2;
                 
                }

                p {
                  font-size: 16px;
                  line-height: 17px;
                  color: #565656;

                  // @include multiline_ellipsis(4);
                  .lightbox-wrapper {
                    .lightbox {
                      .meta {
                        display: none;
                      }
                    }
                  }
                }

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 1rem;
                        border-radius: 0;
                        margin: 0 0 0.5rem;
                      }
                    }
                  }
                }
              }
            }

            .edit_delete_wrap {
              margin: -5px 0 0;
              text-align: right;

              @media (max-width: $phone) {
                margin: 15px 0 0;
              }

              span {
                cursor: pointer;
                line-height: 1;
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                img {
                  height: 16px;
                  width: 16px;
                }
              }

              .delete_commnet {
                img {
                  height: 14px;
                  width: 14px;
                }
              }
            }

            .comment_form + .edit_delete_wrap {
              margin: 5px 0 0;
            }
          }

          .comment_form {
            margin: 0;
          }

        }
        .usc_comment_replay {
          margin: 6px 0 6px 38px;
          background: #eaeaea;
          border-radius: 5px;
          padding: 14px 32px 24px 17px;

          @media (max-width: $phone) {
            margin: 6px 0 6px 18px;
            padding: 17px 18px 20px 18px;
          }

          .usc_ad_profile {
            @extend .d_flex;
            @extend .align_items_center;
            margin-bottom: 14px;

            .user_profile {
              img {
                width: 40px;
                height: 40px;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                margin: 0;
              }
            }

            .usc_user {
              width: calc(100% - 40px);
              padding-left: 10px;

              h4 {
                @extend .text_left;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: $black;
                @extend .text_capitalize;
                letter-spacing: 0.2px;
              }

              span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
              }
            }
          }

          .usc_msg {
            .messages_box_text_more {
              pre {
                margin: 0;

                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }

                a {
                  color: #2f3ab2;
                 
                }

                p {
                  font-size: 16px;
                  line-height: 17px;
                  color: #565656;

                  // @include multiline_ellipsis(4);
                  .lightbox-wrapper {
                    .lightbox {
                      .meta {
                        display: none;
                      }
                    }
                  }
                }

                .ant-skeleton {
                  .ant-skeleton-content {
                    .ant-skeleton-paragraph {
                      li {
                        height: 1rem;
                        border-radius: 0;
                        margin: 0 0 0.5rem;
                      }
                    }
                  }
                }
              }
            }

            .edit_delete_wrap {
              margin: -5px 0 0;
              text-align: right;

              @media (max-width: $phone) {
                margin: 15px 0 0;
              }

              span {
                cursor: pointer;
                line-height: 1;
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                img {
                  height: 16px;
                  width: 16px;
                }
              }

              .delete_commnet {
                img {
                  height: 14px;
                  width: 14px;
                }
              }
            }

            .comment_form + .edit_delete_wrap {
              margin: 5px 0 0;
            }
          }

          .comment_form {
            margin: 0;
          }
        }

        .comment_form {
          margin-left: 38px;
          position: relative;
          border-radius: 5px;

          // &.sub_comment {
          //   margin-left: 136px !important;
          // }

          @media (max-width: $phone) {
            margin-left: 18px;
          }

          .editor {
            border: 1px solid #ddd;
            background: #fff;
            padding: 5px;

            .rdw-editor-toolbar {
              background: #f8f8f8;
            }

            .rdw-image-modal {
              .rdw-image-modal-size {
                display: none;
              }
            }

            .rdw-editor-toolbar {
              border: none;
              border-bottom: 1px solid #ffffff;

              .rdw-link-wrapper {
                .rdw-link-modal {
                  height: 235px;
                }
              }
            }

            .customEditor_textarea {
              .DraftEditor-root {
                .DraftEditor-editorContainer {
                  padding: 0 10px;
                  min-height: 50px;

                  .public-DraftEditor-content {
                    div {
                      figure {
                        .rdw-image-alignment {
                          .rdw-image-imagewrapper {
                            img {
                              object-fit: cover;
                              width: 100%;
                              height: auto;
                              aspect-ratio: 16/9;
                            }
                          }
                        }
                      }
                    }

                    .public-DraftStyleDefault-block {
                      margin: unset;
                    }
                   
                  }

                  
                }
              }
            }

            @media (max-width: $phone) {
              .rdw-editor-toolbar.customEditor_toolbar {
                padding-right: 30px;
              }

              .rdw-option-wrapper {
                margin: 0 1px;
                min-width: 20px;
                padding: 0 3px;

                img {
                  width: 11px;
                }
              }

              .rdw-image-modal,
              .rdw-emoji-modal,
              .rdw-link-modal {
                left: auto;
                right: 0;
              }
            }
          }

          .submit_wrap {
            position: absolute;
            right: 15px;
            top: 14px;

            &_icon_active {
              cursor: pointer;
              width: 12px;
              height: 12px;
              border: 0 !important;
              background-color: #494949;
              -webkit-mask-image: url("../images/send.svg");
              mask-image: url("../images/send.svg");

              &:hover {
                @include filter(brightness(0));
              }
            }

            &_icon_disabled {
              @extend .submit_wrap_icon_active;
              background-color: #c1c1c1;
              cursor: not-allowed;
            }

            &_icon_save_active {
              @extend .submit_wrap_icon_active;
              width: 16px;
              height: 16px;
              background-color: #494949;
              -webkit-mask-image: url("../images/save.svg");
              mask-image: url("../images/save.svg");
            }

            &_icon_save_disabled {
              @extend .submit_wrap_icon_active;
              background-color: #c1c1c1;
              width: 16px;
              height: 16px;
              cursor: not-allowed;
              -webkit-mask-image: url("../images/save.svg");
              mask-image: url("../images/save.svg");
            }
          }
        }
      }

      .usc_reply_sub_child_comment_wrap {
        max-width: 640px;
        width: 100%;
        margin-left: auto;

        .usc_comment_replay {
          margin: 6px 0 6px 38px;
          background: #eaeaea;
          border-radius: 5px;
          padding: 14px 32px 24px 17px;

          @media (max-width: $phone) {
            margin: 6px 0 6px 18px;
            padding: 17px 18px 20px 18px;
          }

          .usc_ad_profile {
            @extend .d_flex;
            @extend .align_items_center;
            margin-bottom: 14px;

            .user_profile {
              img {
                width: 40px;
                height: 40px;
                box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
                margin: 0;
              }
            }

            .usc_user {
              width: calc(100% - 40px);
              padding-left: 10px;

              h4 {
                @extend .text_left;
                font-size: 14px;
                line-height: 17px;
                font-weight: 600;
                color: $black;
                @extend .text_capitalize;
                letter-spacing: 0.2px;
              }

              span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
              }
            }
          }

          .usc_msg {
            pre {
              font-size: 16px;
              line-height: 17px;
              color: #565656;
              overflow: hidden;

              .lightbox-wrapper {
                .meta {
                  display: none;
                }
              }

              a {
                color: #2f3ab2;
              }
            }

            .edit_delete_wrap {
              margin: -5px 0 0;
              text-align: right;

              @media (max-width: $phone) {
                margin: 15px 0 0;
              }

              span {
                cursor: pointer;
                line-height: 1;
                display: inline-block;
                vertical-align: top;
                margin-left: 10px;

                img {
                  height: 16px;
                  width: 16px;
                }
              }

              .delete_commnet {
                img {
                  height: 14px;
                  width: 14px;
                }
              }
            }

            .comment_form + .edit_delete_wrap {
              margin: 5px 0 0;
            }
          }

          .comment_form {
            margin: 0;
          }
        }

        .comment_form {
          margin-left: 38px;
          position: relative;
          border-radius: 5px;

          &.sub_comment {
            margin-left: 136px !important;
          }

          @media (max-width: $phone) {
            margin-left: 18px;
          }

          .editor {
            border: 1px solid #ddd;
            background: #fff;
            padding: 5px;

            .rdw-editor-toolbar {
              background: #f8f8f8;
            }

            .rdw-image-modal {
              .rdw-image-modal-size {
                display: none;
              }
            }

            .rdw-editor-toolbar {
              border: none;
              border-bottom: 1px solid #ffffff;

              .rdw-link-wrapper {
                .rdw-link-modal {
                  height: 235px;
                }
              }
            }

            .customEditor_textarea {
              .DraftEditor-root {
                .DraftEditor-editorContainer {
                  padding: 0 10px;
                  min-height: 50px;

                  .public-DraftEditor-content {
                    div {
                      figure {
                        .rdw-image-alignment {
                          .rdw-image-imagewrapper {
                            img {
                              object-fit: cover;
                              width: 100%;
                              height: auto;
                              aspect-ratio: 16/9;
                            }
                          }
                        }
                      }
                    }

                    .public-DraftStyleDefault-block {
                      margin: unset;
                    }
                  }
                }
              }
            }

            @media (max-width: $phone) {
              .rdw-editor-toolbar.customEditor_toolbar {
                padding-right: 30px;
              }

              .rdw-option-wrapper {
                margin: 0 1px;
                min-width: 20px;
                padding: 0 3px;

                img {
                  width: 11px;
                }
              }

              .rdw-image-modal,
              .rdw-emoji-modal,
              .rdw-link-modal {
                left: auto;
                right: 0;
              }
            }
          }

          .submit_wrap {
            position: absolute;
            right: 15px;
            top: 14px;

            &_icon_active {
              cursor: pointer;
              width: 12px;
              height: 12px;
              border: 0 !important;
              background-color: #494949;
              -webkit-mask-image: url("../images/send.svg");
              mask-image: url("../images/send.svg");

              &:hover {
                @include filter(brightness(0));
              }
            }

            &_icon_disabled {
              @extend .submit_wrap_icon_active;
              background-color: #c1c1c1;
              cursor: not-allowed;
            }

            &_icon_save_active {
              @extend .submit_wrap_icon_active;
              width: 16px;
              height: 16px;
              background-color: #494949;
              -webkit-mask-image: url("../images/save.svg");
              mask-image: url("../images/save.svg");
            }

            &_icon_save_disabled {
              @extend .submit_wrap_icon_active;
              background-color: #c1c1c1;
              width: 16px;
              height: 16px;
              cursor: not-allowed;
              -webkit-mask-image: url("../images/save.svg");
              mask-image: url("../images/save.svg");
            }
          }
        }
      }

      .comment_form {
        &.sub_comment {
          margin-left: 78px;
          margin-bottom: 6px;
        }
      }

      .show_more_comment {
        font-size: 16px;
        line-height: 19px;
        @extend .text_center;
        @extend .text_capitalize;
        color: $black;
        @extend .national_semibold;
        @extend .cursor_pointer;
        margin-top: 170px;

        &:hover {
          color: #990000;
        }

        @media (max-width: $phone) {
          margin-top: 120px;
        }
      }

      .usc_discussion_main + .usc_replaycomment_wrap {
        margin-top: -20px;
        margin-bottom: 27px;

        @media (max-width: $phone) {
          margin-top: -12px;
          margin-bottom: 18px;
        }
      }

      .usc_replaycomment_wrap:empty {
        margin: 0 !important;
      }
    }
  }

  .course_syll_container {
    max-width: 735px;
    margin: 0 auto;

    @media (max-width: $iphone_ii) {
      padding: 0px 29px 0px 18px;
    }

    .syll_title {
      @extend .national_semibold;
      font-size: 24px;
      line-height: 29px;
      @extend .text_capitalize;
      color: $black;
      margin: 0 0 15px;

      @media (max-width: $iphone_ii) {
        max-width: 190px;
      }
    }

    .syll_con {
      margin: 0 0 19px;

      .DraftEditor-root {
        font-size: 18px;
        line-height: 22px;
        color: #565656;
      }
    }
  }

  .course_faq_wrap {
    background: #f2f2f2;
    border-radius: 5px;
    padding: 30px 0;
    // min-height: 930px;
    &.nodata_blank{
      background: #ffffff;
    }

    @media (max-width: $ipad_landscape) {
      padding: 30px 20px;
    }

    @media (max-width: $phone) {
      margin: 35px 30px 0;
      padding: 30px 0;
    }

    .course_syll_container {
      @media (max-width: $phone) {
        padding: 0 28px 0 18px;
      }
    }

    ul.faq_course_list {
      max-width: 735px;
      margin: 33px auto 0;
      // padding: 30px 0 0;
      padding: 0px 0 0;
      // border-top: 0.75px solid #9e9e9e;

      @media (max-width: $phone) {
        margin: 38px auto 0;
        padding: 35px 0 0;
      }

      li {
        margin: 0 0 20px;

        @media (max-width: $phone) {
          padding: 0 18px;
          margin: 0 0 25px;
        }

        .faq_title {
          @extend .national_semibold;
          text-transform: none;
          font-size: 18px;
          line-height: 22px;
          color: $black;
          margin: 0 0 15px;
        }

        .faq_con {
          .DraftEditor-root {
            font-size: 18px;
            line-height: 22px;
            color: #565656;
            max-width: 600px;

            a {
              color: #2f3ab2;
            }
          }

          ol.public-DraftStyleDefault-ol {
            margin-left: 24px;
            margin-top: 20px;
          }

          ul.public-DraftStyleDefault-ul {
            margin-left: 20px;
          }
        }
      }
    }
  }
  .Terms{
    @media (max-width: $phone){
      padding: 0 15px;
    }
    .Terms_container{
      .description-child, .description-child_course{
        @media (max-width: $phone){
          margin: 0 0 0 24px;
        }
      }
    }
  }
}

.custom-tooltip .ant-tooltip-content {
  @media (max-width: $ipadPro) {
    max-width: 195px;
    padding: 10px 10px;
    margin: 4px 10px;
  }

  @media (max-width: $phone) {
    max-width: 195px;
    padding: 10px 10px;
    margin: 0px 10px;
  }

  .ant-tooltip-arrow {
    @media (max-width: $ipadPro) {
      left: 88%;
    }

    @media only screen and (min-width: 577px) and (max-width: 767px) {
      left: 50%;
    }

    @media (max-width: $phone) {
      left: 64%;
    }
  }

  .ant-tooltip-inner {
    @media (max-width: $ipadPro) {
      font-size: 13px;
      line-height: 16px;
      padding: 0;
    }

    @media (max-width: $phone) {
      font-size: 11px;
      line-height: 13px;
      padding: 0;
    }

    p {
      @media (max-width: $ipadPro) {
        margin-top: 5px;
      }

      @media (max-width: $phone) {
        margin-top: 5px;
      }
    }
  }
}

.usc_assignment {
  .usc_ad_main {
    @media (max-width: $iphone_ii) {
      padding-top: 40px;
      padding-left: 18px;
      padding-right: 18px;
    }

    .usc_discussion_main {
      background: #eaeaea;
      border-radius: 5px;
      max-width: 720px;
      width: 100%;
      margin: 0 auto 27px;

      @media (max-width: $phone) {
        margin: 0 auto 18px;
      }

      .usc_profile_msg {
        padding: 12px 30px 20px 18px;

        @media (max-width: $phone) {
          padding: 17px 18px 5px 18px;
        }

        .usc_ad_profile {
          display: flex;
          align-items: center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              text-align: left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: #000000;
              text-transform: capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }

          .pin {
            background: url("../images/pin.svg");
            width: 15px;
            height: 15px;
            font-size: 0;
            cursor: pointer;

            @media (max-width: $phone) {
              align-self: flex-start;
            }
          }

          .unpin {
            cursor: pointer;
            background: url("../images/active_pin.svg");
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .show_more {
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            text-transform: capitalize;
            color: #000000;
            display: block;
            text-align: right;
            margin-top: -12px;
            cursor: pointer;

            @media (max-width: $ipad_landscape) {
              margin-top: 0;
            }
          }
        }
      }

      .usc_ad_img {
        img {
          width: 100%;
          max-width: 100%;
          @extend .d_block;
        }

        .usc_bottom_con {
          background: #c1c1c1;
          padding: 13px 15px 9px 20px;

          @media (max-width: $phone) {
            padding: 14px 24px 10px 18px;
          }

          .article_title {
            @extend .national_semibold;
            font-size: 12px;
            line-height: 15px;
            color: $black;
            @extend .text_left;

            @media (max-width: $phone) {
              font-size: 14px;
              line-height: 16px;
              max-width: 249px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          a {
            font-size: 12px;
            line-height: 15px;
            color: $black;
            @extend .national_regular;
          }
        }
      }

      .usc_comment_wrap {
        padding: 20px 15px 20px 20px;
        line-height: 1;

        @media (max-width: $phone) {
          padding: 19px 15px 19px 18px;
        }

        div {
          @extend .d_inline_block;
          vertical-align: top;
        }

        .like_wrap,
        .comment_wrap {
          padding-left: 18px;
          position: relative;
          font-size: 12px;
          line-height: 15px;
          @extend .text_capitalize;
          color: $black;
          margin-right: 13px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: -1px;
            width: 14px;
            height: 14px;
            background: url("../images/like.svg") no-repeat;
          }
        }

        .comment_wrap {
          &::before {
            top: 1px;
            background: url("../images/comment.svg") no-repeat;
          }
        }

        .like_wrap_active {
          @extend .like_wrap;

          &::before {
            background: url("../images/active_like.svg") no-repeat;
          }
        }

        .comment_wrap_active {
          @extend .like_wrap;

          &::before {
            top: 1px;
            background: url("../images/active_comment.svg") no-repeat;
          }
        }

        .view_comment {
          font-size: 12px;
          line-height: 15px;
          @extend .text_capitalize;
          color: $black;
          margin-left: 32px;
          @extend .cursor_pointer;

          @media (max-width: $phone) {
            margin-left: 0px;
          }

          &:hover {
            color: #990000;
          }

          &.reply_comment {
            float: right;

            @media (max-width: $phone) {
              margin-left: 0;
            }
          }
        }
      }
    }

    .usc_discussion_main_for_admin {
      background:#FAF0E0;
      border-radius: 5px;
      max-width: 720px;
      width: 100%;
      margin: 0 auto 19px;

      @media (max-width: $phone) {
        margin: 0 auto 18px;
      }

      .usc_profile_msg {
        padding: 12px 30px 20px 18px;

        @media (max-width: $phone) {
          padding: 17px 18px 5px 18px;
        }

        .usc_ad_profile {
          display: flex;
          align-items: center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              text-align: left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: #000000;
              text-transform: capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }

          .pin {
            background: url("../images/pin.svg");
            width: 15px;
            height: 15px;
            font-size: 0;
            cursor: pointer;

            @media (max-width: $phone) {
              align-self: flex-start;
            }
          }

          .unpin {
            cursor: pointer;
            background: url("../images/active_pin.svg");
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .show_more {
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            text-transform: capitalize;
            color: #000000;
            display: block;
            text-align: right;
            margin-top: -12px;
            cursor: pointer;

            @media (max-width: $ipad_landscape) {
              margin-top: 0;
            }
          }
        }
      }

      .usc_ad_img {
        img {
          width: 100%;
          max-width: 100%;
          @extend .d_block;
        }

        .usc_bottom_con {
          background: #c1c1c1;
          padding: 13px 15px 9px 20px;

          @media (max-width: $phone) {
            padding: 14px 24px 10px 18px;
          }

          .article_title {
            @extend .national_semibold;
            font-size: 12px;
            line-height: 15px;
            color: $black;
            @extend .text_left;

            @media (max-width: $phone) {
              font-size: 14px;
              line-height: 16px;
              max-width: 249px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          a {
            font-size: 12px;
            line-height: 15px;
            color: $black;
            @extend .national_regular;
          }
        }
      }

      .usc_comment_wrap {
        padding: 20px 15px 20px 20px;
        line-height: 1;

        @media (max-width: $phone) {
          padding: 19px 15px 19px 18px;
        }

        div {
          @extend .d_inline_block;
          vertical-align: top;
        }

        .like_wrap,
        .comment_wrap {
          padding-left: 18px;
          position: relative;
          font-size: 12px;
          line-height: 15px;
          @extend .text_capitalize;
          color: $black;
          margin-right: 13px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: -1px;
            width: 14px;
            height: 14px;
            background: url("../images/like.svg") no-repeat;
          }
        }

        .comment_wrap {
          &::before {
            top: 1px;
            background: url("../images/comment.svg") no-repeat;
          }
        }

        .like_wrap_active {
          @extend .like_wrap;

          &::before {
            background: url("../images/active_like.svg") no-repeat;
          }
        }

        .comment_wrap_active {
          @extend .like_wrap;

          &::before {
            top: 1px;
            background: url("../images/active_comment.svg") no-repeat;
          }
        }

        .view_comment {
          font-size: 12px;
          line-height: 15px;
          @extend .text_capitalize;
          color: $black;
          margin-left: 32px;
          @extend .cursor_pointer;

          @media (max-width: $phone) {
            margin-left: 0px;
          }

          &:hover {
            color: #990000;
          }

          &.reply_comment {
            float: right;

            @media (max-width: $phone) {
              margin-left: 0;
            }
          }
        }
      }
    }
    .usc_discussion_main_for_admin_Mentro{
      background: #fef5d2;
      border-radius: 5px;
      max-width: 720px;
      width: 100%;
      margin: 0 auto 19px;

      @media (max-width: $phone) {
        margin: 0 auto 18px;
      }

      .usc_profile_msg {
        padding: 12px 30px 20px 18px;

        @media (max-width: $phone) {
          padding: 17px 18px 5px 18px;
        }

        .usc_ad_profile {
          display: flex;
          align-items: center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              text-align: left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: #000000;
              text-transform: capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }

          .pin {
            background: url("../images/pin.svg");
            width: 15px;
            height: 15px;
            font-size: 0;
            cursor: pointer;

            @media (max-width: $phone) {
              align-self: flex-start;
            }
          }

          .unpin {
            cursor: pointer;
            background: url("../images/active_pin.svg");
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .show_more {
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            text-transform: capitalize;
            color: #000000;
            display: block;
            text-align: right;
            margin-top: -12px;
            cursor: pointer;

            @media (max-width: $ipad_landscape) {
              margin-top: 0;
            }
          }
        }
      }

      .usc_ad_img {
        img {
          width: 100%;
          max-width: 100%;
          @extend .d_block;
        }

        .usc_bottom_con {
          background: #c1c1c1;
          padding: 13px 15px 9px 20px;

          @media (max-width: $phone) {
            padding: 14px 24px 10px 18px;
          }

          .article_title {
            @extend .national_semibold;
            font-size: 12px;
            line-height: 15px;
            color: $black;
            @extend .text_left;

            @media (max-width: $phone) {
              font-size: 14px;
              line-height: 16px;
              max-width: 249px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          a {
            font-size: 12px;
            line-height: 15px;
            color: $black;
            @extend .national_regular;
          }
        }
      }

      .usc_comment_wrap {
        padding: 20px 15px 20px 20px;
        line-height: 1;

        @media (max-width: $phone) {
          padding: 19px 15px 19px 18px;
        }

        div {
          @extend .d_inline_block;
          vertical-align: top;
        }

        .like_wrap,
        .comment_wrap {
          padding-left: 18px;
          position: relative;
          font-size: 12px;
          line-height: 15px;
          @extend .text_capitalize;
          color: $black;
          margin-right: 13px;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: -1px;
            width: 14px;
            height: 14px;
            background: url("../images/like.svg") no-repeat;
          }
        }

        .comment_wrap {
          &::before {
            top: 1px;
            background: url("../images/comment.svg") no-repeat;
          }
        }

        .like_wrap_active {
          @extend .like_wrap;

          &::before {
            background: url("../images/active_like.svg") no-repeat;
          }
        }

        .comment_wrap_active {
          @extend .like_wrap;

          &::before {
            top: 1px;
            background: url("../images/active_comment.svg") no-repeat;
          }
        }

        .view_comment {
          font-size: 12px;
          line-height: 15px;
          @extend .text_capitalize;
          color: $black;
          margin-left: 32px;
          @extend .cursor_pointer;

          @media (max-width: $phone) {
            margin-left: 0px;
          }

          &:hover {
            color: #990000;
          }

          &.reply_comment {
            float: right;

            @media (max-width: $phone) {
              margin-left: 0;
            }
          }
        }
      }

    }
    .usc_replaycomment_wrap {
      max-width: 720px;
      width: 100%;
      margin: 0 auto 27px;

      .usc_comment_replays{
        margin: 6px 0 6px 38px;
        background:#FAF0E0;
        border-radius: 5px;
        padding: 14px 32px 24px 17px;

        @media (max-width: $phone) {
          margin: 6px 0 6px 18px;
          padding: 17px 18px 20px 18px;
        }

        .usc_ad_profile {
          @extend .d_flex;
          @extend .align_items_center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              @extend .text_left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: $black;
              @extend .text_capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .edit_delete_wrap {
            margin: -5px 0 0;
            // text-align: right;

            @media (max-width: $phone) {
              margin: 15px 0 0;
            }

            .usc_comment_wrap {
              // display: flex;
              // justify-content: space-between;
              // //padding: 20px 15px 20px 20px;
              padding-top: 40px;
              // line-height: 1;

              // @media (max-width: $phone) {
              //   padding: 19px 15px 19px 18px;
              // }

              .reply_edit_delete_icon {
                // @extend .d_inline_block;
                // vertical-align: top;
                float: right;

                .edit_detele_icon {
                  float: left;
                }
              }

              .like_wrap,
              .comment_wrap {
                padding-left: 18px;
                position: relative;
                font-size: 12px;
                line-height: 15px;
                @extend .text_capitalize;
                color: $black;
                margin-right: 13px;
                float: left;

                &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: -1px;
                  width: 14px;
                  height: 14px;
                  background: url("../images/like.svg") no-repeat;
                }
              }

              .comment_wrap {
                &::before {
                  top: 1px;
                  background: url("../images/comment.svg") no-repeat;
                }
              }

              .like_wrap_active {
                @extend .like_wrap;

                &::before {
                  background: url("../images/active_like.svg") no-repeat;
                }
              }

              .comment_wrap_active {
                @extend .like_wrap;

                &::before {
                  top: 1px;
                  background: url("../images/active_comment.svg") no-repeat;
                }
              }

              .view_comment {
                font-size: 12px;
                line-height: 15px;
                @extend .text_capitalize;
                color: $black;
                margin-left: 32px;
                @extend .cursor_pointer;
                float: left;

                @media (max-width: $phone) {
                  margin-left: 0;
                }

                &:hover {
                  color: #990000;
                }

                // &.reply_comment {
                //   float: right;
                // }
              }
            }

            span {
              cursor: pointer;
              line-height: 1;
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .delete_commnet {
              img {
                height: 14px;
                width: 14px;
              }
            }
          }

          .comment_form + .edit_delete_wrap {
            margin: 5px 0 0;
          }
        }

        .comment_form {
          margin: 0;
        }
      }
      .usc_comment_replay_Mentor{

        margin: 6px 0 6px 38px;
        background:#fef5d2;
        border-radius: 5px;
        padding: 14px 32px 24px 17px;

        @media (max-width: $phone) {
          margin: 6px 0 6px 18px;
          padding: 17px 18px 20px 18px;
        }

        .usc_ad_profile {
          @extend .d_flex;
          @extend .align_items_center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              @extend .text_left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: $black;
              @extend .text_capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .edit_delete_wrap {
            margin: -5px 0 0;
            // text-align: right;

            @media (max-width: $phone) {
              margin: 15px 0 0;
            }

            .usc_comment_wrap {
              // display: flex;
              // justify-content: space-between;
              // //padding: 20px 15px 20px 20px;
              padding-top: 40px;
              // line-height: 1;

              // @media (max-width: $phone) {
              //   padding: 19px 15px 19px 18px;
              // }

              .reply_edit_delete_icon {
                // @extend .d_inline_block;
                // vertical-align: top;
                float: right;

                .edit_detele_icon {
                  float: left;
                }
              }

              .like_wrap,
              .comment_wrap {
                padding-left: 18px;
                position: relative;
                font-size: 12px;
                line-height: 15px;
                @extend .text_capitalize;
                color: $black;
                margin-right: 13px;
                float: left;

                &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: -1px;
                  width: 14px;
                  height: 14px;
                  background: url("../images/like.svg") no-repeat;
                }
              }

              .comment_wrap {
                &::before {
                  top: 1px;
                  background: url("../images/comment.svg") no-repeat;
                }
              }

              .like_wrap_active {
                @extend .like_wrap;

                &::before {
                  background: url("../images/active_like.svg") no-repeat;
                }
              }

              .comment_wrap_active {
                @extend .like_wrap;

                &::before {
                  top: 1px;
                  background: url("../images/active_comment.svg") no-repeat;
                }
              }

              .view_comment {
                font-size: 12px;
                line-height: 15px;
                @extend .text_capitalize;
                color: $black;
                margin-left: 32px;
                @extend .cursor_pointer;
                float: left;

                @media (max-width: $phone) {
                  margin-left: 0;
                }

                &:hover {
                  color: #990000;
                }

                // &.reply_comment {
                //   float: right;
                // }
              }
            }

            span {
              cursor: pointer;
              line-height: 1;
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .delete_commnet {
              img {
                height: 14px;
                width: 14px;
              }
            }
          }

          .comment_form + .edit_delete_wrap {
            margin: 5px 0 0;
          }
        }

        .comment_form {
          margin: 0;
        }
      }
      .usc_comment_replay {
        margin: 6px 0 6px 38px;
        background: #eaeaea;
        border-radius: 5px;
        padding: 14px 32px 24px 17px;

        @media (max-width: $phone) {
          margin: 6px 0 6px 18px;
          padding: 17px 18px 20px 18px;
        }

        .usc_ad_profile {
          @extend .d_flex;
          @extend .align_items_center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              @extend .text_left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: $black;
              @extend .text_capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .edit_delete_wrap {
            margin: -5px 0 0;
            // text-align: right;

            @media (max-width: $phone) {
              margin: 15px 0 0;
            }

            .usc_comment_wrap {
              // display: flex;
              // justify-content: space-between;
              // //padding: 20px 15px 20px 20px;
              padding-top: 40px;
              // line-height: 1;

              // @media (max-width: $phone) {
              //   padding: 19px 15px 19px 18px;
              // }

              .reply_edit_delete_icon {
                // @extend .d_inline_block;
                // vertical-align: top;
                float: right;

                .edit_detele_icon {
                  float: left;
                }
              }

              .like_wrap,
              .comment_wrap {
                padding-left: 18px;
                position: relative;
                font-size: 12px;
                line-height: 15px;
                @extend .text_capitalize;
                color: $black;
                margin-right: 13px;
                float: left;

                &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: -1px;
                  width: 14px;
                  height: 14px;
                  background: url("../images/like.svg") no-repeat;
                }
              }

              .comment_wrap {
                &::before {
                  top: 1px;
                  background: url("../images/comment.svg") no-repeat;
                }
              }

              .like_wrap_active {
                @extend .like_wrap;

                &::before {
                  background: url("../images/active_like.svg") no-repeat;
                }
              }

              .comment_wrap_active {
                @extend .like_wrap;

                &::before {
                  top: 1px;
                  background: url("../images/active_comment.svg") no-repeat;
                }
              }

              .view_comment {
                font-size: 12px;
                line-height: 15px;
                @extend .text_capitalize;
                color: $black;
                margin-left: 32px;
                @extend .cursor_pointer;
                float: left;

                @media (max-width: $phone) {
                  margin-left: 0;
                }

                &:hover {
                  color: #990000;
                }

                // &.reply_comment {
                //   float: right;
                // }
              }
            }

            span {
              cursor: pointer;
              line-height: 1;
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .delete_commnet {
              img {
                height: 14px;
                width: 14px;
              }
            }
          }

          .comment_form + .edit_delete_wrap {
            margin: 5px 0 0;
          }
        }

        .comment_form {
          margin: 0;
        }
      }

      .usc_comment_replay_isUSer {
        margin: 6px 0 6px 38px;
        background: #eaeaea;
        border-radius: 5px;
        padding: 14px 32px 24px 17px;

        @media (max-width: $phone) {
          margin: 6px 0 6px 18px;
          padding: 17px 18px 20px 18px;
        }

        .usc_ad_profile {
          @extend .d_flex;
          @extend .align_items_center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              @extend .text_left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: $black;
              @extend .text_capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .edit_delete_wrap {
            margin: -5px 0 0;
            // text-align: right;

            @media (max-width: $phone) {
              margin: 15px 0 0;
            }

            .usc_comment_wrap {
              // display: flex;
              // justify-content: space-between;
              // //padding: 20px 15px 20px 20px;
              padding-top: 40px;
              // line-height: 1;

              // @media (max-width: $phone) {
              //   padding: 19px 15px 19px 18px;
              // }

              .reply_edit_delete_icon {
                // @extend .d_inline_block;
                // vertical-align: top;
                float: right;

                .edit_detele_icon {
                  float: left;
                }
              }

              .like_wrap,
              .comment_wrap {
                padding-left: 18px;
                position: relative;
                font-size: 12px;
                line-height: 15px;
                @extend .text_capitalize;
                color: $black;
                margin-right: 13px;
                float: left;

                &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: -1px;
                  width: 14px;
                  height: 14px;
                  background: url("../images/like.svg") no-repeat;
                }
              }

              .comment_wrap {
                &::before {
                  top: 1px;
                  background: url("../images/comment.svg") no-repeat;
                }
              }

              .like_wrap_active {
                @extend .like_wrap;

                &::before {
                  background: url("../images/active_like.svg") no-repeat;
                }
              }

              .comment_wrap_active {
                @extend .like_wrap;

                &::before {
                  top: 1px;
                  background: url("../images/active_comment.svg") no-repeat;
                }
              }

              .view_comment {
                font-size: 12px;
                line-height: 15px;
                @extend .text_capitalize;
                color: $black;
                margin-left: 32px;
                @extend .cursor_pointer;
                float: left;

                @media (max-width: $phone) {
                  margin-left: 0;
                }

                &:hover {
                  color: #990000;
                }

                // &.reply_comment {
                //   float: right;
                // }
              }
            }

            span {
              cursor: pointer;
              line-height: 1;
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .delete_commnet {
              img {
                height: 14px;
                width: 14px;
              }
            }
          }

          .comment_form + .edit_delete_wrap {
            margin: 5px 0 0;
          }
        }

        .comment_form {
          margin: 0;
        }
      }

      .comment_form {
        margin-left: 38px;
        position: relative;
        border-radius: 5px;

        @media (max-width: $phone) {
          margin-left: 18px;
        }

        .editor {
          border: 1px solid #ddd;
          background: #fff;
          padding: 5px;

          .rdw-editor-toolbar {
            background: #f8f8f8;
          }

          .rdw-image-modal {
            .rdw-image-modal-size {
              display: none;
            }
          }

          .rdw-editor-toolbar {
            border: none;
            border-bottom: 1px solid #ffffff;

            .rdw-link-wrapper {
              .rdw-link-modal {
                height: 235px;
              }
            }
          }

          .customEditor_textarea {
            .DraftEditor-root {
              .DraftEditor-editorContainer {
                padding: 0 10px;
                min-height: 50px;

                .public-DraftEditor-content {
                  div {
                    figure {
                      .rdw-image-alignment {
                        .rdw-image-imagewrapper {
                          img {
                            object-fit: cover;
                            width: 100%;
                            height: auto;
                            aspect-ratio: 16/9;
                          }
                        }
                      }
                    }
                  }

                  .public-DraftStyleDefault-block {
                    margin: unset;
                  }
                }
              }
            }
          }

          @media (max-width: $phone) {
            .rdw-editor-toolbar.customEditor_toolbar {
              padding-right: 30px;
            }

            .rdw-option-wrapper {
              margin: 0 1px;
              min-width: 20px;
              padding: 0 3px;

              img {
                width: 11px;
              }
            }

            .rdw-image-modal,
            .rdw-emoji-modal,
            .rdw-link-modal {
              left: auto;
              right: 0;
            }
          }
        }

        .submit_wrap {
          position: absolute;
          right: 15px;
          top: 14px;

          &_icon_active {
            cursor: pointer;
            width: 12px;
            height: 12px;
            border: 0 !important;
            background-color: #494949;
            -webkit-mask-image: url("../images/send.svg");
            mask-image: url("../images/send.svg");

            &:hover {
              @include filter(brightness(0));
            }
          }

          &_icon_disabled {
            @extend .submit_wrap_icon_active;
            background-color: #c1c1c1;
            cursor: not-allowed;
          }

          &_icon_save_active {
            @extend .submit_wrap_icon_active;
            width: 16px;
            height: 16px;
            background-color: #494949;
            -webkit-mask-image: url("../images/save.svg");
            mask-image: url("../images/save.svg");
          }

          &_icon_save_disabled {
            @extend .submit_wrap_icon_active;
            background-color: #c1c1c1;
            width: 16px;
            height: 16px;
            cursor: not-allowed;
            -webkit-mask-image: url("../images/save.svg");
            mask-image: url("../images/save.svg");
          }
        }
      }
    }

    .usc_reply_sub_comment_wrap {
      max-width: 680px;
      width: 100%;
      margin-left: auto;
      .usc_comment_replays{
        margin: 6px 0 6px 38px;
        background:#FAF0E0;
        border-radius: 5px;
        padding: 14px 32px 24px 17px;

        @media (max-width: $phone) {
          margin: 6px 0 6px 18px;
          padding: 17px 18px 20px 18px;
        }

        .usc_ad_profile {
          @extend .d_flex;
          @extend .align_items_center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              @extend .text_left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: $black;
              @extend .text_capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .edit_delete_wrap {
            margin: -5px 0 0;
            text-align: right;

            @media (max-width: $phone) {
              margin: 15px 0 0;
            }

            span {
              cursor: pointer;
              line-height: 1;
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .delete_commnet {
              img {
                height: 14px;
                width: 14px;
              }
            }
          }

          .comment_form + .edit_delete_wrap {
            margin: 5px 0 0;
          }
        }

        .comment_form {
          margin: 0;
        }
      }
      .usc_comment_replay_Mentor{
        margin: 6px 0 6px 38px;
        background:#fef5d2;
        border-radius: 5px;
        padding: 14px 32px 24px 17px;

        @media (max-width: $phone) {
          margin: 6px 0 6px 18px;
          padding: 17px 18px 20px 18px;
        }

        .usc_ad_profile {
          @extend .d_flex;
          @extend .align_items_center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              @extend .text_left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: $black;
              @extend .text_capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .edit_delete_wrap {
            margin: -5px 0 0;
            text-align: right;

            @media (max-width: $phone) {
              margin: 15px 0 0;
            }

            span {
              cursor: pointer;
              line-height: 1;
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .delete_commnet {
              img {
                height: 14px;
                width: 14px;
              }
            }
          }

          .comment_form + .edit_delete_wrap {
            margin: 5px 0 0;
          }
        }

        .comment_form {
          margin: 0;
        }

      }
      .usc_comment_replay {
        margin: 6px 0 6px 38px;
        background: #eaeaea;
        border-radius: 5px;
        padding: 14px 32px 24px 17px;

        @media (max-width: $phone) {
          margin: 6px 0 6px 18px;
          padding: 17px 18px 20px 18px;
        }

        .usc_ad_profile {
          @extend .d_flex;
          @extend .align_items_center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              @extend .text_left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: $black;
              @extend .text_capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .usc_msg {
          .messages_box_text_more {
            pre {
              margin: 0;

              .lightbox-wrapper {
                .lightbox {
                  .meta {
                    display: none;
                  }
                }
              }

              a {
                color: #2f3ab2;
              }

              p {
                font-size: 16px;
                line-height: 17px;
                color: #565656;

                // @include multiline_ellipsis(4);
                .lightbox-wrapper {
                  .lightbox {
                    .meta {
                      display: none;
                    }
                  }
                }
              }

              .ant-skeleton {
                .ant-skeleton-content {
                  .ant-skeleton-paragraph {
                    li {
                      height: 1rem;
                      border-radius: 0;
                      margin: 0 0 0.5rem;
                    }
                  }
                }
              }
            }
          }

          .edit_delete_wrap {
            margin: -5px 0 0;
            text-align: right;

            @media (max-width: $phone) {
              margin: 15px 0 0;
            }

            span {
              cursor: pointer;
              line-height: 1;
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .delete_commnet {
              img {
                height: 14px;
                width: 14px;
              }
            }
          }

          .comment_form + .edit_delete_wrap {
            margin: 5px 0 0;
          }
        }

        .comment_form {
          margin: 0;
        }
      }

      .comment_form {
        margin-left: 38px;
        position: relative;
        border-radius: 5px;

        // &.sub_comment {
        //   margin-left: 136px !important;
        // }

        @media (max-width: $phone) {
          margin-left: 18px;
        }

        .editor {
          border: 1px solid #ddd;
          background: #fff;
          padding: 5px;

          .rdw-editor-toolbar {
            background: #f8f8f8;
          }

          .rdw-image-modal {
            .rdw-image-modal-size {
              display: none;
            }
          }

          .rdw-editor-toolbar {
            border: none;
            border-bottom: 1px solid #ffffff;

            .rdw-link-wrapper {
              .rdw-link-modal {
                height: 235px;
              }
            }
          }

          .customEditor_textarea {
            .DraftEditor-root {
              .DraftEditor-editorContainer {
                padding: 0 10px;
                min-height: 50px;

                .public-DraftEditor-content {
                  div {
                    figure {
                      .rdw-image-alignment {
                        .rdw-image-imagewrapper {
                          img {
                            object-fit: cover;
                            width: 100%;
                            height: auto;
                            aspect-ratio: 16/9;
                          }
                        }
                      }
                    }
                  }

                  .public-DraftStyleDefault-block {
                    margin: unset;
                  }
                }
              }
            }
          }

          @media (max-width: $phone) {
            .rdw-editor-toolbar.customEditor_toolbar {
              padding-right: 30px;
            }

            .rdw-option-wrapper {
              margin: 0 1px;
              min-width: 20px;
              padding: 0 3px;

              img {
                width: 11px;
              }
            }

            .rdw-image-modal,
            .rdw-emoji-modal,
            .rdw-link-modal {
              left: auto;
              right: 0;
            }
          }
        }

        .submit_wrap {
          position: absolute;
          right: 15px;
          top: 14px;

          &_icon_active {
            cursor: pointer;
            width: 12px;
            height: 12px;
            border: 0 !important;
            background-color: #494949;
            -webkit-mask-image: url("../images/send.svg");
            mask-image: url("../images/send.svg");

            &:hover {
              @include filter(brightness(0));
            }
          }

          &_icon_disabled {
            @extend .submit_wrap_icon_active;
            background-color: #c1c1c1;
            cursor: not-allowed;
          }

          &_icon_save_active {
            @extend .submit_wrap_icon_active;
            width: 16px;
            height: 16px;
            background-color: #494949;
            -webkit-mask-image: url("../images/save.svg");
            mask-image: url("../images/save.svg");
          }

          &_icon_save_disabled {
            @extend .submit_wrap_icon_active;
            background-color: #c1c1c1;
            width: 16px;
            height: 16px;
            cursor: not-allowed;
            -webkit-mask-image: url("../images/save.svg");
            mask-image: url("../images/save.svg");
          }
        }
      }
    }

    .usc_reply_sub_child_comment_wrap {
      max-width: 640px;
      width: 100%;
      margin-left: auto;

      .usc_comment_replay {
        margin: 6px 0 6px 38px;
        background: #eaeaea;
        border-radius: 5px;
        padding: 14px 32px 24px 17px;

        @media (max-width: $phone) {
          margin: 6px 0 6px 18px;
          padding: 17px 18px 20px 18px;
        }

        .usc_ad_profile {
          @extend .d_flex;
          @extend .align_items_center;
          margin-bottom: 14px;

          .user_profile {
            img {
              width: 40px;
              height: 40px;
              box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
              margin: 0;
            }
          }

          .usc_user {
            width: calc(100% - 40px);
            padding-left: 10px;

            h4 {
              @extend .text_left;
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              color: $black;
              @extend .text_capitalize;
              letter-spacing: 0.2px;
            }

            span {
              display: block;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }

        .usc_msg {
          pre {
            font-size: 16px;
            line-height: 17px;
            color: #565656;
            overflow: hidden;

            .lightbox-wrapper {
              .meta {
                display: none;
              }
            }

            a {
              color: #2f3ab2;
            }
          }

          .edit_delete_wrap {
            margin: -5px 0 0;
            text-align: right;

            @media (max-width: $phone) {
              margin: 15px 0 0;
            }

            span {
              cursor: pointer;
              line-height: 1;
              display: inline-block;
              vertical-align: top;
              margin-left: 10px;

              img {
                height: 16px;
                width: 16px;
              }
            }

            .delete_commnet {
              img {
                height: 14px;
                width: 14px;
              }
            }
          }

          .comment_form + .edit_delete_wrap {
            margin: 5px 0 0;
          }
        }

        .comment_form {
          margin: 0;
        }
      }

      .comment_form {
        margin-left: 38px;
        position: relative;
        border-radius: 5px;

        &.sub_comment {
          margin-left: 136px !important;
        }

        @media (max-width: $phone) {
          margin-left: 18px;
        }

        .editor {
          border: 1px solid #ddd;
          background: #fff;
          padding: 5px;

          .rdw-editor-toolbar {
            background: #f8f8f8;
          }

          .rdw-image-modal {
            .rdw-image-modal-size {
              display: none;
            }
          }

          .rdw-editor-toolbar {
            border: none;
            border-bottom: 1px solid #ffffff;

            .rdw-link-wrapper {
              .rdw-link-modal {
                height: 235px;
              }
            }
          }

          .customEditor_textarea {
            .DraftEditor-root {
              .DraftEditor-editorContainer {
                padding: 0 10px;
                min-height: 50px;

                .public-DraftEditor-content {
                  div {
                    figure {
                      .rdw-image-alignment {
                        .rdw-image-imagewrapper {
                          img {
                            object-fit: cover;
                            width: 100%;
                            height: auto;
                            aspect-ratio: 16/9;
                          }
                        }
                      }
                    }
                  }

                  .public-DraftStyleDefault-block {
                    margin: unset;
                  }
                }
              }
            }
          }

          @media (max-width: $phone) {
            .rdw-editor-toolbar.customEditor_toolbar {
              padding-right: 30px;
            }

            .rdw-option-wrapper {
              margin: 0 1px;
              min-width: 20px;
              padding: 0 3px;

              img {
                width: 11px;
              }
            }

            .rdw-image-modal,
            .rdw-emoji-modal,
            .rdw-link-modal {
              left: auto;
              right: 0;
            }
          }
        }

        .submit_wrap {
          position: absolute;
          right: 15px;
          top: 14px;

          &_icon_active {
            cursor: pointer;
            width: 12px;
            height: 12px;
            border: 0 !important;
            background-color: #494949;
            -webkit-mask-image: url("../images/send.svg");
            mask-image: url("../images/send.svg");

            &:hover {
              @include filter(brightness(0));
            }
          }

          &_icon_disabled {
            @extend .submit_wrap_icon_active;
            background-color: #c1c1c1;
            cursor: not-allowed;
          }

          &_icon_save_active {
            @extend .submit_wrap_icon_active;
            width: 16px;
            height: 16px;
            background-color: #494949;
            -webkit-mask-image: url("../images/save.svg");
            mask-image: url("../images/save.svg");
          }

          &_icon_save_disabled {
            @extend .submit_wrap_icon_active;
            background-color: #c1c1c1;
            width: 16px;
            height: 16px;
            cursor: not-allowed;
            -webkit-mask-image: url("../images/save.svg");
            mask-image: url("../images/save.svg");
          }
        }
      }
    }

    .comment_form {
      &.sub_comment {
        margin-left: 78px;
        margin-bottom: 6px;
      }
    }

    .show_more_comment {
      font-size: 16px;
      line-height: 19px;
      @extend .text_center;
      @extend .text_capitalize;
      color: $black;
      @extend .national_semibold;
      @extend .cursor_pointer;
      margin-top: 170px;

      &:hover {
        color: #990000;
      }

      @media (max-width: $phone) {
        margin-top: 120px;
      }
    }

    .usc_discussion_main + .usc_replaycomment_wrap {
      margin-top: -20px;
      margin-bottom: 27px;

      @media (max-width: $phone) {
        margin-top: -12px;
        margin-bottom: 18px;
      }
    }

    .usc_replaycomment_wrap:empty {
      margin: 0 !important;
    }
  }
}

.heading_title{
  text-transform: none;
  font-size: 26px;
}
.Terms_container{
  margin: 40px 0;
  .Terms_title{
    text-transform: none;
    &.heading_title{
      text-transform: none;
      margin-bottom: 30px;
    }
  }
  .Terms_description{
    margin: 10px 0;
    .description_title{
      text-transform: none;
      font-size: 15px;
      font: normal;
    }
    .description-child_course{
      // wi
      margin: 0 0 0 99px;
    }
    .description-child{
      margin: 0 0 0 99px;
    }
  }
}

.post-delete{
  float: right;
  .view_comment.reply_comment{
    float: none !important;
    margin-left: 0 !important;
    margin-right: 12px;
  }
  .delete_commnet{
    cursor: pointer;
  }
}